import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Alert, Dialog, DialogContent, DialogActions, LinearProgress, List, Tooltip } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ReplayIcon from '@mui/icons-material/Replay';
import CheckIcon from '@mui/icons-material/Check';
import {
    verificationSideNav,
    secondaryListItems,
    disbursementSideNav,
} from "../Side_Nav/listItems";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { DialogTitle, MenuItem, TextField } from "@material-ui/core";
import LoanDetails from "../Common/basicLoanDetails";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Axios from "axios";
import { base_url1 } from "../../constants";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Footer from "./../Common/footer";
import { useNavigate } from "react-router-dom";
import SessionModal from "./../Common/SessionModal";
import DoneIcon from '@mui/icons-material/Done';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import {
    getDisbursementDetails,
    submitDisbursal,
    getTransactionDetails,
    getBalance,
    pennyDropCheck,
    confirmDisbursementApi,
    disbursementStatus,
    updateDetails
} from "./../apiCalls/api";
import { useState } from "react";
const label = { inputProps: { "aria-label": "Checkbox demo" } };
import AppHeader from "./../Common/header";
import CloseIcon from '@mui/icons-material/Close';
// import {
//     Checkbox,
//     Select,
//     FormControl,
//     InputLabel,
//     FormGroup,
//     FormControlLabel,
// } from "@mui/material";
import Select from "react-select";
import { color } from "chart.js/helpers";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    "& .MuiDrawer-paper": {
        position: "relative",
        whiteSpace: "nowrap",
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: "border-box",
        ...(!open && {
            overflowX: "hidden",
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up("sm")]: {
                width: theme.spacing(9),
            },
        }),
    },
}));

const mdTheme = createTheme();
function ConfirmDisbursement() {
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const reduxData = useSelector(
        (state) => state.applications?.loan_basic_info?.Loan_Application_id
    );
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [accountVerified, setaccountVerified] = useState(false);
    const [open, setOpen] = React.useState(true);
    const modeOfTransfer = [
        { value: 'neft', label: 'NEFT' },
        { value: 'imps', label: 'IMPS' },
        { value: 'rtgs', label: 'RTGS' },
        { value: 'ft', label: 'FT' },
    ];
    const [errorMessage, setErrorMessage] = React.useState("");
    const [modeOfTransferSelect, setModeOfTransferSelect] = useState("");
    const [balanceLoading, setBalanceLoading] = useState(true);
    const [balanceMessage, setBalanceMessage] = useState("");
    const [balanceStatus, setBalanceStatus] = useState(false);
    const [editData, setEditData] = useState({});
    const [balance, setBalance] = useState();
    const [DisbursementStatus, setDisbursementStatus] = useState("");
    const [disbursementPayments, setDisbursementPayments] = useState(false);
    const [showDisbursementStatusAlert, setShowDisbursementStatusAlert] = useState(false);
    const [pennyDropClicked, setPennyDropClicked] = useState(false);
    const [pennyDropLoading, setPennyDropLoading] = useState(false);
    const [pennyDropMessage, setPennyDropMessage] = useState("");
    const [pennyDropStatus, setPennyDropStatus] = useState(false);
    const [disbursementConfirmed, setDisbursementConfirmed] = useState(false);
    const [confirmDisbursementStatus, setConfirmDisbursementStatus] = useState(true);
    const [incompleateDetails, setIncompleateDetails] = useState(false);
    const [openTextfeild, setOpenTextfeild] = useState(false);
    const [editLoading, setEditLoading] = useState(false);
    const [editError, showEditError] = useState(false);
    const [successPennyDrop, setSuccessPennyDrop] = useState(false);
    const handleCloseErrorModal = () => {
        setIsErrorModalOpen(false);
        navigate("/login");
    };

    const toggleDrawer = () => {
        setOpen(!open);
    };

    useEffect(async () => {
        try{
        const statusResponse = await disbursementStatus(reduxData);
            if (statusResponse?.response_message == "SUCCESS") {
                setConfirmDisbursementStatus(false);
                setDisbursementStatus("Loan has been disbursed");
                setDisbursementPayments(false);
                setDisbursementConfirmed(true);
            }
            else {
                setDisbursementStatus(statusResponse?.data?.response_message);
                setDisbursementPayments(true);
                setShowDisbursementStatusAlert(true);
            }
        }
        catch(error){
            setDisbursementStatus(statusResponse?.data?.response_message);
            setDisbursementPayments(true);
            setShowDisbursementStatusAlert(true);
        }
},[]);

    const formatKey = (key) => {
        return key
            .replace(/_/g, " ")
            .replace(/^\w/, (c) => c.toUpperCase())
            .replace(/\b\w/g, (c) => c.toUpperCase());
    };

    const changeTransferMode = (e) => {
        setModeOfTransferSelect(e.value);
    }

    const editButtonClicked = (e) => {
        setOpenTextfeild(!openTextfeild);
        showEditError(false);
    }

    const handleChange = (key,value) =>{
        if(key == "beneficiary_account_number"){
            key = "account_number"
        }
        if(key == "beneficiary_name"){
            key = "account_name"
        }
        if(key == "beneficiary_ifsc"){
            key = "account_ifsc"
        }
        setEditData((prevData) => ({
            ...prevData,
            [key]: value,
          }));
    }


    const submitEditDetails = async(e) =>{
        showEditError(false);
        setEditLoading(true);
        try {
            const statusResponse = await updateDetails(reduxData, editData);
            if(statusResponse?.data?.status_code == 200){
             setOpenTextfeild(!openTextfeild);
             getDetails();
             setEditLoading(false);
            }
            else{
            showEditError(true);
            setEditLoading(false);
            }
        }
        catch (error) {
            console.log(error);
            showEditError(true);
            setEditLoading(false);
        }
        // setOpenTextfeild(!openTextfeild);
    }


    useEffect(() => {
        console.log(modeOfTransferSelect);
    }, [modeOfTransferSelect]);


    useEffect(() => {
        const fetchDetails = async () => {
            getDetails();
        };
        fetchDetails();
    }, []); 

    const getDetails = async() =>{
        try {
            setEditLoading(false);
            setLoading(true);
            const result = await getDisbursementDetails(reduxData);
            setLoading(false);
            setData(result.data.response_data);
            if (
                ["loan_id","approved_loan_amount","beneficiary_account_number","beneficiary_ifsc","beneficiary_name","disbursement_amount","is_disbursed","ready_for_disbursement"].some
                (key => result.data.response_data?.[key] == null || result.data.response_data?.[key] === "")
              ){
                setIncompleateDetails(true);
              }
              else{
                setIncompleateDetails(false);
              }
        } catch (error) {
            if (error.message === "AxiosError: Request failed with status code 401") {
                setIsErrorModalOpen(true);
                console.log(error);
            }
        }
    }

    useEffect(async () => {
        try {
            const balanceStatus = await getBalance();
            // console.log(balanceStatus.data);
            // setBalanceMessage(balanceStatus?.data?.response_message);
            setBalanceMessage("The server encountered an internal error")
            if(balanceStatus?.status==200){
                setBalanceLoading(false);
                setBalanceStatus(true);
                setBalance(balanceStatus?.data?.response_data);
            }
            else{
                setBalanceLoading(false);
                setBalanceStatus(false);
            }
            // console.log(balanceStatus.status);
            // setBalanceLoading(false);
            
        }
        catch (error) {
            setBalanceStatus(false);
            setBalanceLoading(false);
        }
    }, [])

    const handlePennyDrop = async () => {
        setPennyDropLoading(true)
        setPennyDropClicked(true);
        try {
            const pennyDropResult = await pennyDropCheck(reduxData);
            setPennyDropMessage(pennyDropResult?.data?.response_message);
            // console.log(pennyDropResult?.status);
            setPennyDropLoading(false);
            if (pennyDropResult?.status == 200) {
                setPennyDropStatus(true);
                getDetails();
                setSuccessPennyDrop(true);
            }
            else {
                setPennyDropStatus(false);
            }
        }
        catch(error){
            if (error.message === "AxiosError: Request failed with status code 401") {
                setIsErrorModalOpen(true);
                console.error(error);
            }
            setPennyDropStatus(false);
        }
    };

    const handleDisburse = async () => {
        // setLoading(true);
        setDisbursementStatus("");
        try {
            setConfirmDisbursementStatus(false);
            const result = await confirmDisbursementApi(reduxData, "", modeOfTransferSelect);
            console.log(result?.status);
            if(result?.status===200){
                setDisbursementConfirmed(true);
                setDisbursementStatus("Disbursement done successfully");
                setDisbursementPayments(false);
            }
            else{
                setDisbursementConfirmed(false);
                setDisbursementStatus(result?.data?.response_message);
            }
        } catch (error) {
            if (error.message) {
                setErrorMessage(error.message);
                setDisbursementConfirmed(false);
                setDisbursementStatus(result?.data?.response_message);
            }
        }
    };

    return (<>
        <ThemeProvider theme={mdTheme}>
            <div>
                {/* Your component content */}
                <SessionModal
                    isOpen={isErrorModalOpen}
                    onClose={handleCloseErrorModal}
                />
            </div>
            <Box sx={{ display: "flex", mb: 6 }}>
                <CssBaseline />
                <AppBar position="absolute" open={open}>
                    <Toolbar
                        sx={{
                            pr: "24px", // keep right padding when drawer closed
                        }}
                    >
                        <AppHeader
                            open={open}
                            toggleDrawer={toggleDrawer}
                            title="Confirm Disbursement"
                        />
                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open}>
                    <Toolbar
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            px: [1],
                        }}
                    >
                        <IconButton onClick={toggleDrawer}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </Toolbar>
                    <Divider />
                    <List component="nav">
                        {disbursementSideNav}
                        <Divider sx={{ my: 1 }} />
                        {/* {secondaryListItems} */}
                    </List>
                </Drawer>
                <Box sx={{ width: "100%" }} style={{ marginTop: "5%" }}>
                    <div style={{ marginTop: "1%" }}>
                        <LoanDetails></LoanDetails>
                        <div className="details-box2" style={{ height: "68vh" }}>
                            <Box sx={{ width: "100%" }}>
                                {/* <div style={{ marginTop: "1%" }}> */}
                                <div className="details-box2">
                                    <div style={{ display: "flex", flexDirection: "row" }}></div>
                                    <div style={{display:"flex"}}>
                                    <Typography 
                                       style={{ 
                                        fontSize: "1.5rem", 
                                        fontWeight: "800", 
                                        color: "var(--primary-color)",
                                        display: "flex",
                                        alignItems: "center",
                                        flexWrap: "wrap",
                                        }} 
                                        sx={{ ml: 3 }}
                                    >Disbursement Details</Typography>
                                    {incompleateDetails && !loading &&
                                    <Tooltip title="Edit Details ">
                                      <Button
                                          sx={{ textTransform: "none", minWidth:"auto", padding:"4px",whiteSpace:"nowrap"}}
                                          variant="contained"
                                          className="download-button"
                                          onClick={(e) => editButtonClicked(e)}
                                      >
                                          <ModeEditIcon fontSize="3rem"/>
                                      </Button>
                                    </Tooltip>
                                    }
                                    </div>
                                    {loading ? ( // Display loader if loading is true
                                        <div
                                            style={{
                                                textAlign: "center",
                                                padding: "20px",
                                                marginTop: "150px",
                                            }}
                                        >
                                            <CircularProgress />
                                            <Typography>Loading...</Typography>
                                        </div>
                                    ) : (
                                        <>
                                            {showDisbursementStatusAlert || disbursementConfirmed ? (<>
                                                {DisbursementStatus!=="" ? (<>
                                                    <Alert severity={confirmDisbursementStatus ? "info" : disbursementConfirmed ? "success": "error"} sx={{mt:2}}>
                                                      Disbursement Status : {DisbursementStatus}
                                                    </Alert>    
                                                </>):(<><LinearProgress sx={{mt:1}}/></>)}
                                                </>):(<></>)}
                                            <Grid container spacing={2} alignItems="flex-start">
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={6}
                                                    // sx={{
                                                    //   display: "flex",
                                                    //   justifyContent: "flex-end",
                                                    // }}
                                                    style={{
                                                        width: "100%",
                                                        marginTop: "1%",
                                                        marginLeft: "1%",
                                                    }}
                                                >
                                                    <div style={{ marginTop: "3%" }}>
                                                        {Object.keys(data)
                                                            .sort((a, b) => {
                                                                if (a === "loan_id") return -1;
                                                                if (b === "loan_id") return 1;
                                                                return 0;
                                                            })
                                                            .map((key, index) => (<>
                                                                <Typography
                                                                    key={key}
                                                                    sx={{
                                                                        ml: 5, mb: 1,
                                                                        flex: 1,
                                                                        animation: `appearFromSideSmooth 0.6s ease-in-out ${index * 0.15}s forwards`,
                                                                        opacity: 0,
                                                                        transform: "translateX(-30px) scale(0.5)",
                                                                    }}
                                                                // style={{color:"#ffffff"}}
                                                                >
                                                                    <Typography
                                                                        // whiteSpace="nowrap"
                                                                        sx={{ minWidth: "300px", mr: "10px", display: "inline-block" }}
                                                                        component="div"
                                                                        style={{
                                                                            fontSize: "1rem",
                                                                            //   fontWeight: "700",
                                                                            display: "inline-block",
                                                                        }}
                                                                    >
                                                                        {key === "is_disbursed" ?
                                                                            (<span>
                                                                                Already Disbursed
                                                                            </span>
                                                                            ) : (
                                                                                <>{formatKey(key)}</>
                                                                            )}
                                                                    </Typography>
                                                                    {
                                                                            key === "beneficiary_ifsc" ||
                                                                            key === "beneficiary_account_number" ||
                                                                            key === "beneficiary_name" ||
                                                                            key === "loan_id" ?
                                                                            (
                                                                                <b>:{data[key]}</b>
                                                                            ) : (
                                                                                <>
                                                                                    {
                                                                                        key === "is_disbursed" ? (
                                                                                            data[key].toLocaleString("en-IN") === "true" ? (
                                                                                                <><span style={{
                                                                                                    color: "var(--green-color)",
                                                                                                    fontWeight: 700,
                                                                                                    // display: "flex"
                                                                                                }}>:<DoneIcon style={{ marginTop: "-3px" }} />
                                                                                                    <Typography style={{ fontWeight: 700, display: "inline" }}>Yes</Typography>
                                                                                                </span>
                                                                                                </>
                                                                                            ) : (
                                                                                                <><span style={{
                                                                                                    color: "var(--red-color)",
                                                                                                    fontWeight: 700,
                                                                                                    // display: "flex"
                                                                                                }}>:<CloseIcon style={{ marginTop: "-3px" }} />
                                                                                                    <Typography style={{ fontWeight: 700, display: "inline" }}>No</Typography>
                                                                                                </span>
                                                                                                </>
                                                                                            )
                                                                                        ) : key === "ready_for_disbursement" ? (
                                                                                            data[key] === 1 ? (
                                                                                                <><span style={{
                                                                                                    color: "var(--green-color)",
                                                                                                    fontWeight: 700,
                                                                                                }}>:<DoneIcon style={{ marginTop: "-3px" }} />
                                                                                                    <Typography style={{ fontWeight: 700, display: "inline" }}>Yes</Typography>
                                                                                                </span>
                                                                                                </>
                                                                                            ) : (
                                                                                                <><span style={{
                                                                                                    color: "var(--red-color)",
                                                                                                    fontWeight: 700,
                                                                                                }}>:<CloseIcon style={{ marginTop: "-3px" }} />
                                                                                                    <Typography style={{ fontWeight: 700, display: "inline" }}>No</Typography>
                                                                                                </span>
                                                                                                </>
                                                                                            )
                                                                                        ) : key === "disbursement_amount" && data[key] != null ? (
                                                                                            <b>:₹{Number(data[key]).toLocaleString("en-IN", {
                                                                                                maximumFractionDigits: 2,
                                                                                            })}</b>
                                                                                        ) : key === "approved_loan_amount" && data[key] != null ? (
                                                                                            <b>:₹{parseFloat(data[key]).toLocaleString("en-IN", {
                                                                                                maximumFractionDigits: 2,
                                                                                            })}</b>
                                                                                        ):(
                                                                                            <b>:</b>
                                                                                        )
                                                                                    }

                                                                                </>
                                                                            )}

                                                                </Typography>
                                                            </>
                                                            ))}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={0.5}>
                                                    <Divider />  
                                                </Grid>
                                                {disbursementPayments && 
                                                //confirmDisbursementStatus &&
                                                    <Grid item xs={12} md={4.5} sx={{ ml: 1 }}>
                                                        <Grid container>
                                                            <Grid item xs={6} md={12} sx={{ mt: 2 }}>
                                                                {successPennyDrop ? (<>
                                                                    <Typography
                                                                            component="div"
                                                                            style={{
                                                                                color: "var(--green-color)",
                                                                                fontSize: "1rem",
                                                                                fontWeight: "600",
                                                                                display: "inline-block",
                                                                            }}
                                                                        >
                                                                            {/* Account Verified- Penny Drop Successful. */}
                                                                            {/* {pennyDropMessage} */}
                                                                            Penny Drop Successful
                                                                        </Typography>
                                                                </>):(<>
                                                                {!pennyDropClicked ? (
                                                                    <>
                                                                        <Typography
                                                                            component="div"
                                                                            style={{
                                                                                color: "#3f48cc",
                                                                                fontSize: "1rem",
                                                                                fontWeight: "100",
                                                                                display: "inline-block",
                                                                                cursor:"pointer"
                                                                            }}
                                                                            sx={{ mt: 2, mb: 2 }}
                                                                        >
                                                                            <a onClick={handlePennyDrop}>
                                                                                Verify the account
                                                                            </a>
                                                                        </Typography>
                                                                        <Typography style={{ fontSize: "13px", fontStyle: "italic" }}>
                                                                            Using penny drop
                                                                        </Typography>
                                                                    </>
                                                                ) : (<>
                                                                    {pennyDropLoading ? (<>
                                                                        <Typography style={{ fontStyle: "italic" }}>
                                                                            <CircularProgress size={"1rem"} style={{ color: "var(--primary-color)" }} sx={{ mr: 1 }}/>Checking<div class="dot-flashing"></div>
                                                                        </Typography>
                                                                    </>) : (<>
                                                                        <Typography
                                                                            component="div"
                                                                            style={{
                                                                                color: pennyDropStatus ? "var(--green-color)" : "var(--red-color)",
                                                                                fontSize: "1rem",
                                                                                fontWeight: "600",
                                                                                display: "inline-block",
                                                                            }}
                                                                        >
                                                                            {/* Account Verified- Penny Drop Successful. */}
                                                                            {/* {pennyDropMessage} */}
                                                                            {pennyDropStatus ? (<>Penny Drop Successful</>) : (<>Penny Drop Failed. Try Again<ReplayIcon onClick={handlePennyDrop} style={{cursor:"pointer"}}/></>)}
                                                                        </Typography>
                                                                    </>)}
                                                                </>)}
                                                                </>)}
                                                            </Grid>
                                                            <Grid item xs={5} md={12} sx={{ mt: 2 }}>
                                                                <Typography
                                                                    component="div"
                                                                    style={{
                                                                        fontSize: "1rem",
                                                                        fontWeight: "100",
                                                                        display: "flex",
                                                                        alignItems: "center"
                                                                    }}
                                                                    sx={{ mt: 2 }}
                                                                >Mode of Transfer:&nbsp;
                                                                    <div style={{ width: '180px' }}>
                                                                        <Select
                                                                            placeholder="Select Option"
                                                                            options={modeOfTransfer}
                                                                            onChange={(e) => changeTransferMode(e)}
                                                                            style={{ display: "inline-block", width: 20, height: 10 }}
                                                                        />
                                                                    </div>
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container sx={{ mt: 3 }}>
                                                            {balanceLoading ? (<>
                                                                <Grid
                                                                    item
                                                                    xs={6}
                                                                    md={12}
                                                                    sx={{
                                                                        display: "flex",
                                                                    }}
                                                                    style={{
                                                                        width: "98%",
                                                                        // marginTop: "3%",
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        component="div"
                                                                        style={{
                                                                            fontSize: "1rem",
                                                                            fontWeight: "100",
                                                                            alignItems: "center",
                                                                            flexWrap: "wrap",
                                                                            fontStyle: "italic",
                                                                        }}
                                                                        sx={{ mt: 2 }}
                                                                    >
                                                                        {/* <a onClick={handleVerifyAccountClick}> */}
                                                                        <CircularProgress size={"1rem"} style={{ color: "#3f48cc" }} />&nbsp;Checking Balance Status<div class="dot-flashing"></div>
                                                                        {/* </a> */}
                                                                    </Typography>
                                                                </Grid>
                                                            </>) : (<>
                                                                <Grid
                                                                    item
                                                                    xs={6}
                                                                    md={12}
                                                                    sx={{
                                                                        display: "flex",
                                                                    }}
                                                                    style={{
                                                                        width: "98%",
                                                                        // marginTop: "3%",
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        style={{
                                                                            fontSize: "1rem",
                                                                            fontWeight: "100",
                                                                            display: "inline-block",
                                                                        }}
                                                                    >
                                                                        Balance:
                                                                        {balanceStatus ? (<>
                                                                          {parseFloat(data?.disbursement_amount) >
                                                                           parseFloat(balance?.available_balance) ? (<>
                                                                            <Typography
                                                                                style={{
                                                                                    display: "inline",
                                                                                    color: "var(--red-color)",
                                                                                    verticalAlign: "middle",
                                                                                }}
                                                                            >
                                                                                {" "}
                                                                                NOT AVAILABLE
                                                                                <CloseIcon style={{ verticalAlign: "top" }} />

                                                                            </Typography>
                                                                          </>):(<>
                                                                            <Typography
                                                                                style={{
                                                                                    display: "inline",
                                                                                    color: "var(--green-color)",
                                                                                    verticalAlign: "middle",
                                                                                }}
                                                                            >
                                                                                {" "}
                                                                                AVAILABLE
                                                                                <CheckIcon style={{ verticalAlign: "top" }} />
                                                                            </Typography>
                                                                          </>)}
                                                                        </>):(<>
                                                                        <Typography
                                                                            style={{
                                                                                fontSize: "1rem",
                                                                                fontWeight: "100",
                                                                                display: "inline-block",
                                                                                color:"var(--red-color)"
                                                                            }}
                                                                        >
                                                                            {balanceMessage}
                                                                        </Typography>
                                                                        </>)
                                                                        }
                                                                    </Typography>
                                                                </Grid></>)}
                                                            <Grid
                                                                item
                                                                xs={5}
                                                                md={12}
                                                                sx={{
                                                                    display: "flex",
                                                                    justifyContent: "flex-end",
                                                                }}
                                                                style={{
                                                                    // width: "95%",
                                                                    marginTop: "15%",
                                                                    marginRight: "1%"
                                                                }}
                                                            >
                                                                <Button
                                                                    sx={{ textTransform: "none" }}
                                                                    variant="contained"
                                                                    className="download-button"
                                                                    onClick={handleDisburse}
                                                                    // onClick={submitPDC}
                                                                    disabled={modeOfTransferSelect == "" || !balanceStatus || !confirmDisbursementStatus || incompleateDetails}
                                                                >Confirm Disbursement
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                }
                                        </Grid></>)}
                                </div>
                            </Box>
                        </div>
                    </div>
                    {openTextfeild &&
                        <Dialog
                            open={openTextfeild}
                            onClose={openTextfeild == false}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle style={{color:"var(--primary-color)"}}>
                                <Grid container>
                                    <Grid item xs={11} sm={11} md={11}>
                                      <b>Edit Details</b>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1}>
                                      <Tooltip title="Close"><IconButton onClick={(e) => editButtonClicked(e)}><CloseIcon sx={{ borderRadius: "50%", color:"var(--primary-color)"}} /></IconButton></Tooltip>
                                    </Grid>
                                </Grid>
                                {editError && 
                                <div>
                                    <Alert severity="error">
                                      Failed to update. Try again later
                                    </Alert>
                                </div>}
                            </DialogTitle>
                            <DialogContent style={{display:"flex", justifyContent:"center"}}>
                                <div>
                                    {Object.keys(data)
                                        .map((key) => 
                                       (data[key] == null || data[key] === "") ? (<>
                                       <Grid sx={{m:1}}>
                                            <Typography
                                                key={key}
                                                sx={{
                                                    m: 1,
                                                    flex: 1,
                                                }}
                                            >
                                                    <Typography
                                                      sx={{ minWidth: "300px", display: "inline-block"}}
                                                      component="div"
                                                      style={{
                                                            fontSize: "1rem",
                                                            display: "inline-block",
                                                            }}
                                                    >
                                                        <>{formatKey(key)}</>
                                                    </Typography>
                                                        {/* {(data[key] == null || data[key] === "") ? ( */}
                                                        <TextField
                                                          key={key}
                                                          name={key}
                                                          variant="outlined" 
                                                          size="small"
                                                          disabled={editLoading}
                                                          onChange={(event)=>handleChange(key, event.target.value)}
                                                        />
                                                        {/* ):(<>:{data[key]}</>)
                                                        } */}
                                                
                                            </Typography>
                                            </Grid>
                                        </>
                                        ):null)
                                    }
                                </div>
                            </DialogContent>
                            <DialogActions>
                              <Button
                                 variant="contained"
                                 onClick={(e) => submitEditDetails(e)}
                                 disabled={editLoading}
                                 startIcon={editLoading ? <CircularProgress size={20} color="inherit" /> : null}
                                 >Submit
                              </Button>
                            </DialogActions>
                        </Dialog >
                    }
                </Box>
            </Box>

            <Footer />
        </ThemeProvider>
    </>)
}

export default function confirmDisbursement() {
    return (<ConfirmDisbursement />);
}