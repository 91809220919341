import * as React from "react";
import { useState } from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  verificationSideNavBranchManager,
  secondaryListItems,
} from "../Side_Nav/listItems";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TextField } from "@material-ui/core";
import LoanDetails from "../Common/basicLoanDetails";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Axios from "axios";
import { base_url1 } from "../../constants";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import LogoutIcon from "@mui/icons-material/Logout";
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import LinearProgress from "@mui/material/LinearProgress";
import AppHeader from "./../Common/header";
import Footer from "./../Common/footer";
import { useNavigate } from "react-router-dom";
import SessionModal from "./../Common/SessionModal";
import {
  sendInitiateVerificationMail,
  getInitiateVerification,
  createModifyCMSRecords,
  initiateExternalAgency,
} from "./../apiCalls/api";
const columns = [
  {
    field: "title",
    headerName: "",
    flex: 1,
    minWidth: 90,
  },
  // {
  //   editable: true,
  //   field: "internal",
  //   headerName: "Internal",
  //   flex: 1,
  //   minWidth: 90,
  //   type: "boolean",
  // },
  {
    editable: true,
    field: "investigation",
    headerName: "Investigation Channel",
    flex: 1,
    minWidth: 90,
    type: "singleSelect",
    valueOptions: ["Internal", "External"],
    renderCell: (params) => {
      return (
        <div style={{ width: "100%", display: "contents" }}>
          <span style={{ width: "80%" }}>{params.row.investigation} </span>
          <IconButton
            style={{ width: "20%" }}
            edge="end"
            color="inherit"
            aria-label="close"
          >
            <ExpandMoreIcon />
          </IconButton>
        </div>
      );
    },
  },
  {
    editable: true,
    field: "waiver",
    headerName: "Waiver",
    flex: 1,
    minWidth: 90,
    type: "boolean",
  },
  {
    editable: true,
    field: "agencyType",
    headerName: "External Agency Type",
    flex: 1,
    minWidth: 90,
  },
  {
    editable: true,
    field: "instances",
    headerName: "No. Of Instances",
    flex: 1,
    minWidth: 90,
    type: "number",
  },
  {
    editable: true,
    field: "reason",
    headerName: "Waiver Reason",
    flex: 1,
    minWidth: 90,
  },
];
const columns1 = [
  {
    field: "title",
    headerName: "",
    flex: 1,
    minWidth: 90,
  },
  {
    editable: true,
    field: "investigation",
    headerName: "Investigation Channel",
    flex: 1,
    minWidth: 90,
    type: "singleSelect",
    valueOptions: ["Internal", "External"],
    renderCell: (params) => {
      return (
        <div style={{ width: "100%", display: "contents" }}>
          <span style={{ width: "80%" }}>{params.row.investigation} </span>
          <IconButton
            style={{ width: "20%" }}
            edge="end"
            color="inherit"
            aria-label="close"
          >
            <ExpandMoreIcon />
          </IconButton>
        </div>
      );
    },
  },
  {
    editable: true,
    field: "waiver",
    headerName: "Waiver",
    flex: 1,
    minWidth: 90,
    type: "boolean",
  },
  {
    editable: true,
    field: "agencyType",
    headerName: "External Agency Type",
    flex: 1,
    minWidth: 90,
  },
  {
    editable: true,
    field: "instances",
    headerName: "No. Of Instances",
    flex: 1,
    minWidth: 90,
    type: "number",
  },
  {
    editable: true,
    field: "reason",
    headerName: "Waiver Reason",
    flex: 1,
    minWidth: 90,
  },
  {
    editable: true,
    field: "decision",
    headerName: "FI Result",
    flex: 1,
    minWidth: 90,
    type: "singleSelect",
    valueOptions: ["Pass", "Fail"],
    renderCell: (params) => {
      return (
        <div style={{ width: "100%", display: "contents" }}>
          <span style={{ width: "80%" }}>{params.row.decision} </span>
          <IconButton
            style={{ width: "20%" }}
            edge="end"
            color="inherit"
            aria-label="close"
          >
            <ExpandMoreIcon />
          </IconButton>
        </div>
      );
    },
  },
];
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();
const handleChangeInput = (event) => {};
function Verification() {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const [isAPILoading, setisAPILoading] = React.useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [isVerified, setisVerified] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [mailBody, setmailBody] = React.useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [mailAttachment, setmailAttachment] = React.useState([]);
  const handleClickOpen = () => {
    setOpen1(true);
  };
  const handleClose = () => {
    setOpen1(false);
  };
  const handleClick = () => {
    setOpen1(!open1);
  };
  const [open, setOpen] = React.useState(true);
  const [rows, setRows] = React.useState(null);
  const reduxData = useSelector(
    (state) => state.applications?.loan_basic_info?.Loan_Application_id
  );
  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
    navigate("/login");
  };
  const makerChecker = useSelector((state) => state.makerCheckAccess);
  const initiateverification = async () => {
    setisAPILoading(true);
    const order = [
      "Income",
      "Bank Verification",
      "Office Address",
      "Residential Address",
    ];

    const arrangedData = order.map((title) => {
      return rows.find((item) => item.title === title);
    });
    try {
      const results = await sendInitiateVerificationMail(reduxData);
      if (results) {
        setisAPILoading(false);
      }
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setisAPILoading(false);
        setIsErrorModalOpen(true);
        console.error(error);
      }
    }
  };
  const rows1 = [
    {
      id: 0,
      title: "Bank Verification",
      loan_id: reduxData,
      internal: 0,
      external: 0,
      waiver: false,
      agencyType: "",
      instances: 0,
      reason: "",
      remark: "",
      decision: "Pass",
      investigation: "External",
    },
    {
      id: 0,
      title: "Income",
      internal: 0,
      loan_id: reduxData,
      external: 0,
      waiver: false,
      agencyType: "",
      instances: 0,
      reason: "",
      remark: "",
      decision: "Pass",
      investigation: "Internal",
    },
    {
      id: 0,
      title: "Office Address",
      internal: 0,
      loan_id: reduxData,
      external: 0,
      waiver: false,
      agencyType: "",
      instances: 0,
      reason: "",
      remark: "",
      decision: "Pass",
      investigation: "External",
    },
    {
      id: 0,
      title: "Residential Address",
      internal: 0,
      loan_id: reduxData,
      external: 0,
      waiver: false,
      agencyType: "",
      instances: 0,
      reason: "",
      remark: "",
      decision: "Pass",
      investigation: "External",
    },
  ];
  useEffect(() => {
    async function getData() {
      setisAPILoading(true);
      try {
        const result = await getInitiateVerification(reduxData);
        if (result.data.data.length == 0) {
          rows1.forEach(async (item) => {
            try{
            const result1 = await createModifyCMSRecords(item);
            if (result1) {
              setRowsAPI();
            }
          }catch(error){
            console.log(error);
          }
        }
        );
        } else {
          // if there is no data in the database
          //setRows(result.data.data);
          const newList = result.data.data;
          newList.forEach((item) => {
            if (item.waiver == 0) {
              item.waiver = false;
            } else {
              item.waiver = true;
            }
          });
          setRows(result.data.data);
        }
      } catch (error) {
        if (
          error.message === "AxiosError: Request failed with status code 401"
        ) {
          setisAPILoading(false);
          setIsErrorModalOpen(true);
          console.error(error);
        }
      }
      try {
        const result = await initiateExternalAgency(reduxData);
        if (result.data.loan_id_match == true) {
          setmailBody(result.data.bodyPreview);
          setmailAttachment(result.data.URL);
          setisVerified(true);
          setisAPILoading(false);
        } else {
          setisVerified(false);
          setisAPILoading(false);
        }
      } catch (error) {
        if (
          error.message === "AxiosError: Request failed with status code 401"
        ) {
          setisAPILoading(false);
          setIsErrorModalOpen(true);
          console.error(error);
        }
      }
    }
    getData();
  }, [reduxData]);
  const setRowsAPI = async () => {
    setRows([]);
    try {
      const result = await getInitiateVerification(reduxData);
      if (result.data.data) {
        const newList = result.data.data;
        newList.forEach((item) => {
          if (item.waiver == 0) {
            item.waiver = false;
          } else {
            item.waiver = true;
          }
        });
        setRows(result.data.data);
      }
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setisAPILoading(false);
        setIsErrorModalOpen(true);
        console.error(error);
      }
    }
  };
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const downloadAttachments = () => {
    mailAttachment.forEach(async (item) => {
      window.open(item, "_blank");
    });
  };
  const [updatedRows, setUpdatedRows] = useState([]);

  const handleEditCellChange = async (params) => {
    try {
      const result = await createModifyCMSRecords(params);
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setisAPILoading(false);
        setIsErrorModalOpen(true);
        console.error(error);
      }
    }
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <div>
        {/* Your component content */}
        <SessionModal
          isOpen={isErrorModalOpen}
          onClose={handleCloseErrorModal}
        />
      </div>
      <Box sx={{ display: "flex", mb: 6 }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <AppHeader
              open={open}
              toggleDrawer={toggleDrawer}
              title="Initiate Verification"
            />
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {verificationSideNavBranchManager}
            <Divider sx={{ my: 1 }} />
            {/* {secondaryListItems} */}
          </List>
        </Drawer>
        <Box sx={{ width: "100%" }} style={{ marginTop: "5%" }}>
          {isAPILoading ? (
            <Grid item xs={12}>
              <LinearProgress
                sx={{
                  color: "#3f48cc",
                }}
                size={40}
                thickness={4}
              />
            </Grid>
          ) : null}
          {/* <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label="Initiate Verification"
                {...a11yProps(0)}
                style={{ textTransform: "capitalize" }}
              />
              {isVerified ? (
                <Tab
                  label="Approve Verification"
                  {...a11yProps(1)}
                  style={{ textTransform: "capitalize" }}
                />
              ) : null}
            </Tabs>
          </Box> */}
          <div style={{ marginTop: "1%" }}>
            <LoanDetails></LoanDetails>
            {/* <TabPanel value={value} index={0}>
              {rows ? (
                <div className="details-box">
                  <DataGrid
                    columns={columns}
                    rows={rows}
                    processRowUpdate={handleEditCellChange}
                    onEditCellChange={handleEditCellChange}
                    editMode="cell"
                    {...updatedRows}
                    style={{ height: "45vh" }}
                  />
                </div>
              ) : null}
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                style={{
                  width: "100%",
                }}
              >
                <Button
                  disabled={!makerChecker}
                  sx={{ textTransform: "none" }}
                  variant="contained"
                  className="download-button"
                  onClick={initiateverification}
                >
                  Initiate Verification
                </Button>
              </Grid>
            </TabPanel> */}

            {rows ? (
              <div className="details-box">
                {/* <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                  style={{
                    width: "100%",
                  }}
                >
                  <Button
                    sx={{ textTransform: "none" }}
                    variant="contained"
                    className="download-button"
                    onClick={handleClickOpen}
                  >
                    View Mail
                  </Button>
                  <BootstrapDialog
                    className="mailCSS"
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open1}
                  >
                    <BootstrapDialogTitle
                      id="customized-dialog-title"
                      onClose={handleClose}
                    >
                      External Agency Email Details
                      <Grid item xs={12} sm={6} md={6} mt={2}>
                        <Typography
                          sx={{ mr: 4, flex: 1 }}
                          component="div"
                          style={{ fontWeight: "800" }}
                        >
                          Mail Body
                        </Typography>
                        <TextField
                          fullWidth
                          type="text"
                          variant="outlined"
                          name="Personal_Discussion"
                          value={mailBody}
                          inputProps={{
                            style: {
                              height: "200px",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} mt={2}>

                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={6}
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          style={{
                            width: "100%",
                          }}
                        >
                          <Button
                            sx={{ textTransform: "none" }}
                            variant="contained"
                            className="download-button"
                            onClick={downloadAttachments}
                          >
                            Download Attachments
                          </Button>
                        </Grid>
                      </Grid>
                    </BootstrapDialogTitle>
                    <DialogContent></DialogContent>
                  </BootstrapDialog>
                </Grid> */}
                <DataGrid
                  columns={columns1}
                  rows={rows}
                  processRowUpdate={handleEditCellChange}
                  onEditCellChange={handleEditCellChange}
                  editMode="cell"
                  {...updatedRows}
                  style={{ height: "45vh" }}
                />
              </div>
            ) : null}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
              style={{
                width: "100%",
              }}
            ></Grid>
          </div>
        </Box>
      </Box>
      <Footer />
    </ThemeProvider>
  );
}

export default function verification() {
  return <Verification />;
}
