import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  mainListItems,
  secondaryListItems,
  mainListItemsIndividual,
} from "../Side_Nav/listItems";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import LoanDetails from "../Common/basicLoanDetails";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import { TextField } from "@material-ui/core";
import { useEffect, useRef } from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import Tooltip from "@mui/material/Tooltip";
import AppHeader from "./../Common/header";
import Footer from "./../Common/footer";
import {
  updateBasicDetails,
  getIdentificationCard,
  uploadIdentificationCard,
  getPassport,
  getPAN,
  getDownloadUrl,
} from "./../apiCalls/api";
import SessionModal from "./../Common/SessionModal";
import { useState } from "react";
import UploadIcon from "@mui/icons-material/Upload";
import DownloadIcon from "@mui/icons-material/Download";
import LinearProgress from "@mui/material/LinearProgress";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const drawerWidth = 240;
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();

function DashboardContent() {
  const [company_address, setAddress] = React.useState();
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [director_details, setDetails] = React.useState();
  const [personalInformation, setpersonalInformation] = React.useState();
  const [coapplicantInformation, setCoapplicantInformation] = React.useState();
  const [faceMatchRemarks, setFaceMatchRemarks] = React.useState();
  const [base64file, setbase64file] = React.useState();
  const [passportFile, setpassportFile] = React.useState(null);
  const [panFile, setpanFile] = React.useState(null);
  const [guarantorInformation, setguarantorInformation] = React.useState();
  const [isAPILoading, setisAPILoading] = React.useState(false);
  const currentUser = useSelector((state) => state.currentUserDetails);
  const [value, setValue] = React.useState(0);
  const [error, setError] = useState(null);
  const [downloadUrl, setDownloadUrl] = React.useState();
  const [regexErrors, setRegexErrors] = useState({});
  const [disableUpdate, setDisableUpdate] = useState(false);
  const allowedFileTypes = ["image/jpeg", "image/png", "application/pdf"];
  const regexPatterns = {
    PAN: /^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]$/,
    Aadhar:  /^[a-zA-Z0-9\s,]*$/,
    Annual_Income: /^[-+]?\d*\.?\d*$/,
    Email_Id: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    Phone: /^(?:\+?\d{1,3})?\d{10}$/,
    Consent: /^(?:0|1|Y|N)?$/,
    own_house: /^(?:0|1)?$/,
    Date_of_Birth: /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-\d{4}$/,
    Full_Name: /^[A-Za-z ]*$/,
    Loan_Amount: /^[-+]?\d*\.?\d*$/,
    face_match_remarks: /^.*$/,
    mothers_name: /^[A-Za-z ]*$/,
    Loan_Tenure: /^[-+]?\d*\.?\d*$/,
    landmark: /^.*$/,
    shop_name: /^.*$/,
    shop_address: /^.*$/,
  };

  const reduxData = useSelector(
    (state) => state.applications?.loan_basic_info?.Loan_Application_id
  );
  const company_address1 = useSelector(
    (state) => state.applications?.company_details
  );
  const loan_guarantor = useSelector(
    (state) => state.applications?.loan_guarantor
  );
  const director_details1 = useSelector(
    (state) => state.applications?.company_director_details
  );
  const loanType = useSelector(
    (state) => state.applications?.loan_basic_info?.loan_type
  );
  const personalDetails = useSelector(
    (state) => state.applications?.loan_personal_info
  );
  const coapplicantDetails= useSelector(
    (state) => state.applications?.coapplicant_details
  );
  useEffect(() => {
    setAddress(company_address1);
    setDetails(director_details1);
    setpersonalInformation(personalDetails);
    setCoapplicantInformation(coapplicantDetails);
    setFaceMatchRemarks(personalDetails?.face_match_remarks);
    setguarantorInformation(loan_guarantor);
  }, [company_address1, director_details1]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    async function getData() {
      try {
        setisAPILoading(true);
        const result = await getIdentificationCard(reduxData);
        if (result.data.data.length > 0) {
          console.log("result is", result.data.data);
          setbase64file(result.data.data);
          setisAPILoading(false);
        } else {
          setisAPILoading(false);
          // if there is no data in the database
        }
      } catch (error) {
        if (
          error.message === "AxiosError: Request failed with status code 401"
        ) {
          setIsErrorModalOpen(true);
          setisAPILoading(false);
          console.error(error);
        }
      }
    }
    getData();
  }, []);
  useEffect(() => {
    async function getData() {
      try {
        setisAPILoading(true);
        const result = await getPassport(reduxData);
        if (result.data.data.length > 0) {
          console.log("result is", result.data.data);
          setpassportFile(result.data.data[0]);
          setisAPILoading(false);
        } else {
          setisAPILoading(false);
          // if there is no data in the database
        }
      } catch (error) {
        if (
          error.message === "AxiosError: Request failed with status code 401"
        ) {
          setIsErrorModalOpen(true);
          setisAPILoading(false);
          console.error(error);
        }
      }
      try {
        setisAPILoading(true);
        const result = await getPAN(reduxData);
        if (result.data.data.length > 0) {
          console.log("result is", result.data.data);
          setpanFile(result.data.data[0]);
          setisAPILoading(false);
        } else {
          setisAPILoading(false);
          // if there is no data in the database
        }
      } catch (error) {
        if (
          error.message === "AxiosError: Request failed with status code 401"
        ) {
          setIsErrorModalOpen(true);
          setisAPILoading(false);
          console.error(error);
        }
      }
    }
    getData();
  }, []);
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const downloadFile = async (e) => {
    try {
      const result = await getDownloadUrl(e);
      setDownloadUrl(result.data.response_data.download_url);
      window.open(result.data.response_data.download_url, "_blank");
    } catch (error) {
      if (error.message) {
        console.error("Error:", error);
      }
    }
  };
  const validateInput = (key, value) => {
    if (regexPatterns[key]) {
      return regexPatterns[key].test(value);
    }
    return true; // No validation needed for other keys
  };
  useEffect(()=>{
    const hasErrors = Object.values(regexErrors).some((error) => error !== "");
    setDisableUpdate(hasErrors);
  },[regexErrors])
  const handlePersonalInformation = (e) => {
    console.log(e);
    const { name, value } = e.target;
    const isValid = validateInput(name, value);
    setRegexErrors((prevErrors) => ({
      ...prevErrors,
      [name]: isValid ? "" : `Invalid ${name.replace(/_/g, " ")}`,
    }));
    setpersonalInformation({
      ...personalInformation,
      [e.target.name]: e.target.value,
    });
  };
  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
    navigate("/login");
  };
  const handleGuarantorInformation = (e, index) => {
    const updatedDetails = [...guarantorInformation]; // Create a copy of the array

    // Update the specific item in the array based on the index
    updatedDetails[index] = {
      ...updatedDetails[index],
      [e.target.name]: e.target.value,
    };

    setguarantorInformation(updatedDetails);
  };
  const handleDirectorInformation = (e, index) => {
    const updatedDetails = [...director_details]; // Create a copy of the array

    // Update the specific item in the array based on the index
    updatedDetails[index] = {
      ...updatedDetails[index],
      [e.target.name]: e.target.value,
    };

    setDetails(updatedDetails); // Update the state with the updated array
  };
  const handleCompanyInformation = (e) => {
    setAddress({
      ...company_address,
      [e.target.name]: e.target.value,
    });
  };
  const updatePersonalDetails = async () => {
    console.log(personalInformation);
    const companyDetails = {};
    const directorDetails = {};
    const loanGuarantor = {};
    try {
      const result = await updateBasicDetails(
        reduxData,
        personalInformation,
        companyDetails,
        directorDetails,
        loanGuarantor
      );
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setIsErrorModalOpen(true);
        console.error(error);
      }
    }
  };
  const updateDirectorDetails = async () => {
    console.log(personalInformation);
    const personalDetails = {};
    const loanInformation = {};
    const companyDetails = {};
    try {
      const result = await updateBasicDetails(
        reduxData,
        personalDetails,
        companyDetails,
        director_details,
        loanInformation
      );
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setIsErrorModalOpen(true);
        console.error(error);
      }
    }
  };
  const updateGuarantorDetails = async () => {
    console.log(personalInformation);
    const personalDetails = {};
    const directorDetails = {};
    const companyDetails = {};
    try {
      const result = await updateBasicDetails(
        reduxData,
        personalDetails,
        companyDetails,
        directorDetails,
        guarantorInformation
      );
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setIsErrorModalOpen(true);
        console.error(error);
      }
    }
  };
  const updateCompanyDetails = async () => {
    console.log(personalInformation);
    const personalDetails = {};
    const directorDetails = {};
    const loanGuarantor = {};
    try {
      const result = await updateBasicDetails(
        reduxData,
        personalDetails,
        company_address,
        directorDetails,
        loanGuarantor
      );
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setIsErrorModalOpen(true);
        console.error(error);
      }
    }
  };
  const fileInputRef = useRef(null);
  const [identificationCard, setidentificationCard] = React.useState(null);
  const handleUploadClick = () => {
    // Trigger the file input click event
    fileInputRef.current.click();
  };

  const fileCheck = (file) => {
    const fileType = file.type;
    const maxFilesize = 15 * 1024 * 1024;

    if (file.size > maxFilesize) {
      return "File size exceeds the limit (15MB).";
    } else if (!allowedFileTypes.includes(fileType)) {
      return "Invalid File Type. Only JPEG, PNG and PDF allowed.";
    } else {
      return null; // No error
    }
  };

  const handleFileChange = async (e) => {
    const selected = e.target.files[0];
    const error = fileCheck(selected);

    if (error) {
      setError(error);
      e.target.value = "";
      alert(error);
    } else {
      const selectedFile = e.target.files[0];
      if (selectedFile) {
        // Handle the selected file, for example, you can upload it or process it
        console.log(`Selected file: ${selectedFile.name}`);
        setidentificationCard(selectedFile);
        try {
          setisAPILoading(true);
          let data = null;
          data = new FormData();
          data.append("source", "cms");
          data.append("loan_id", reduxData);
          data.append("file", selectedFile);
          const results = await uploadIdentificationCard(data);
          if (results) {
            try {
              const result = await getIdentificationCard(reduxData);

              if (result.data.data.length > 0) {
                console.log("result is", result.data.data);
                setbase64file(result.data.data);
                setisAPILoading(false);
              } else {
                // if there is no data in the database
                console.log("Error");
                setisAPILoading(false);
              }
            } catch (error) {
              if (
                error.message ===
                "AxiosError: Request failed with status code 401"
              ) {
                setIsErrorModalOpen(true);
                console.error(error);
                setisAPILoading(false);
              }
            }
          }
        } catch (error) {
          if (
            error.message === "AxiosError: Request failed with status code 401"
          ) {
            setIsErrorModalOpen(true);
            console.error(error);
          }
        }
      }
    }
  };

  const downloadIdentificationCard = async () => {
    try {
      for (const item of base64file) {
        try {
          const result = await getDownloadUrl(item.blob_url);
          const downloadUrl = result.data.response_data.download_url;
          setDownloadUrl(downloadUrl);
          window.open(result.data.response_data.download_url, "_blank");
        } catch (error) {
          if (error.message) {
            console.error("Error:", error);
          }
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const downloadPassport = async () => {
    try {
      const result = await getDownloadUrl(passportFile.blob_url);
      setDownloadUrl(result.data.response_data.download_url);
      window.open(result.data.response_data.download_url, "_blank");
    } catch (error) {
      if (error.message) {
        console.error("Error:", error);
      }
    }
  };
  const downloadPAN = async () => {
    try {
      const result = await getDownloadUrl(panFile.blob_url);
      setDownloadUrl(result.data.response_data.download_url);
      window.open(result.data.response_data.download_url, "_blank");
    } catch (error) {
      if (error.message) {
        console.error("Error:", error);
      }
    }
  };
  return (
    <ThemeProvider theme={mdTheme}>
      <div>
        {/* Your component content */}
        <SessionModal
          isOpen={isErrorModalOpen}
          onClose={handleCloseErrorModal}
        />
      </div>
      <Box sx={{ display: "flex", mb: 6 }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <AppHeader open={open} toggleDrawer={toggleDrawer} title="KYC" />
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          {loanType == "Individual" ? (
            <List component="nav">
              {mainListItemsIndividual}
              <Divider />
              {currentUser.role_name === "credit_head" ||
              currentUser.role_name === "credit_ops" ? (
                <>{secondaryListItems}</>
              ) : null}
            </List>
          ) : (
            <List component="nav">
              {mainListItems}
              <Divider />
              {currentUser.role_name === "credit_head" ||
              currentUser.role_name === "credit_ops" ? (
                <>{secondaryListItems}</>
              ) : null}
            </List>
          )}
        </Drawer>
        <Box sx={{ width: "100%" }} style={{ marginTop: "5%" }}>
          {isAPILoading ? (
            <Grid item xs={12}>
              <LinearProgress
                sx={{
                  color: "#3f48cc",
                }}
                size={40}
                thickness={4}
              />
            </Grid>
          ) : null}
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            {loanType == "Individual" ? (
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label="Personal Information"
                  {...a11yProps(0)}
                  style={{ textTransform: "capitalize" }}
                />
                <Tab
                  label="Guarantor Details"
                  {...a11yProps(1)}
                  style={{ textTransform: "capitalize" }}
                />
                <Tab
                  label="Co-Applicant Details"
                  {...a11yProps(3)}
                  style={{ textTransform: "capitalize" }}
                />
              </Tabs>
            ) : (
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label="Company Information"
                  {...a11yProps(0)}
                  style={{ textTransform: "capitalize" }}
                />
                <Tab
                  label="Directors Information"
                  {...a11yProps(1)}
                  style={{ textTransform: "capitalize" }}
                />
                <Tab
                  label="Guarantor Details"
                  {...a11yProps(2)}
                  style={{ textTransform: "capitalize" }}
                />
              </Tabs>
            )}
          </Box>
          <div style={{ marginTop: "1%" }}>
            <LoanDetails></LoanDetails>
            {loanType == "Individual" ? (
              <>
                <TabPanel value={value} index={0}>
                  {loanType == "Individual" ? (
                    <>
                      {personalInformation != undefined ? (
                        <div className="details-box">
                          <Grid item xs={12} sx={{ pt: 4 }}>
                            <Grid container spacing={4} sx={{ pl: 1, pr: 1 }}>
                              <Grid item xs={11} sm={11} md={11}>
                                <Typography
                                  sx={{ mr: 4, flex: 1, pt: 2, pb: 2 }}
                                  component="div"
                                  style={{
                                    fontWeight: "800",
                                    color: "#3f48cc",
                                  }}
                                >
                                  Personal Information
                                </Typography>
                              </Grid>
                              {currentUser.role_name != "branch_manager" ? (
                                <Grid
                                  item
                                  xs={12}
                                  md={1}
                                  sx={{ display: "flex", mt: 3 }}
                                >
                                  {/* Upload Icon with Tooltip */}
                                  <Tooltip title="Upload Aadhar Card" arrow>
                                    <div
                                      onClick={handleUploadClick}
                                      style={{
                                        cursor: "pointer",
                                        color: "#3f48cc",
                                      }}
                                    >
                                      <UploadIcon sx={{ m: 1 }} />
                                    </div>
                                  </Tooltip>
                                  {/* {base64file != null &&
                                  base64file != undefined ? (
                                    <>
                                      {" "}
                                      <Tooltip
                                        title="Download Aadhar Card"
                                        arrow
                                      >
                                        <div
                                          onClick={downloadIdentificationCard}
                                          style={{
                                            cursor: "pointer",
                                            color: "#3f48cc",
                                          }}
                                        >
                                          <DownloadIcon sx={{ m: 1 }} />
                                        </div>
                                      </Tooltip>
                                    </>
                                  ) : null} */}

                                  {/* Hidden file input */}
                                  <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={{ display: "none" }}
                                    onChange={handleFileChange}
                                  />
                                </Grid>
                              ) : null}
                              <Grid container spacing={2} sx={{ ml: 3 }}>
                                {passportFile != null &&
                                  passportFile != undefined && (
                                    <Grid item xs={12} sm={6}>
                                      <Paper elevation={3} sx={{ p: 2 }}>
                                        <Typography
                                          sx={{ mr: 4, flex: 1, pt: 2, pb: 2 }}
                                          component="div"
                                          style={{
                                            fontWeight: "500",
                                            color: "#3f48cc",
                                          }}
                                        >
                                          Passport/Aadhar Document
                                        </Typography>
                                        <Tooltip title="Passport Details" arrow>
                                          <div
                                            onClick={downloadPassport}
                                            style={{
                                              cursor: "pointer",
                                              color: "#3f48cc",
                                            }}
                                          >
                                            <DownloadIcon sx={{ m: 1 }} />
                                          </div>
                                        </Tooltip>
                                      </Paper>
                                    </Grid>
                                  )}

                                {panFile != null && panFile != undefined && (
                                  <Grid item xs={12} sm={6}>
                                    <Paper elevation={3} sx={{ p: 2 }}>
                                      <Typography
                                        sx={{ mr: 4, flex: 1, pt: 2, pb: 2 }}
                                        component="div"
                                        style={{
                                          fontWeight: "500",
                                          color: "#3f48cc",
                                        }}
                                      >
                                        PAN
                                      </Typography>
                                      <Tooltip title="PAN" arrow>
                                        <div
                                          onClick={downloadPAN}
                                          style={{
                                            cursor: "pointer",
                                            color: "#3f48cc",
                                          }}
                                        >
                                          <DownloadIcon sx={{ m: 1 }} />
                                        </div>
                                      </Tooltip>
                                    </Paper>
                                  </Grid>
                                )}
                              </Grid>
                              {Object.entries(personalInformation).map(
                                ([key, value]) => {
                                  if (
                                    key === "aadhar_info" ||
                                    key == "Applicant_Personal_Id" ||
                                    key == "Loan_Application_Id" ||
                                    key == "Annual_Income" ||
                                    key == "Phone" ||
                                    key == "Aadhar" ||
                                    key == "Loan_Tenure" ||
                                    key == "own_house" ||
                                    key == "Email_Id" ||
                                    key == "shop_name" ||
                                    key == "shop_address" ||
                                    key == "mothers_name" ||
                                    key == "landmark" ||
                                    key == "live_photo"
                                  ) {
                                    return null; // Skip the iteration for "aadhar_info" key
                                  }
                                  return (
                                    <Grid
                                      key={key}
                                      item
                                      xs={12}
                                      sm={6}
                                      md={6}
                                      mt={3}
                                      style={{zIndex: 0}}
                                    >
                                      <TextField
                                        fullWidth
                                        type="text"
                                        variant="outlined"
                                        name={key}
                                        label={key
                                          .replace(/_/g, " ")
                                          .toLowerCase()
                                          .split(" ")
                                          .map(
                                            (word) =>
                                              word.charAt(0).toUpperCase() +
                                              word.slice(1)
                                          )
                                          .join(" ")}
                                        value={
                                          isNaN(value)
                                            ? value
                                            : value === "" || value === null
                                            ? null
                                            : Number(value).toLocaleString()
                                        }              
                                        onChange={(e) => {
                                          const newValue =
                                            e.target.value.replace(/,/g, ""); // Remove commas
                                          handlePersonalInformation({
                                            target: {
                                              name: key,
                                              value: newValue,
                                            },
                                          });
                                        }}
                                        error={Boolean(regexErrors[key])}
                                        helperText={regexErrors[key]}
                                      />
                                    </Grid>
                                  );
                                }
                              )}{" "}
                              {Object.entries(personalInformation).map(
                                ([key, value]) => {
                                  if (key != "Phone") {
                                    return null; // Skip the iteration for "aadhar_info" key
                                  }
                                  return (
                                    <Grid
                                      key={key}
                                      item
                                      xs={12}
                                      sm={6}
                                      md={6}
                                      mt={3}
                                      style={{zIndex: 0}}
                                    >
                                      <TextField
                                        fullWidth
                                        type="text"
                                        variant="outlined"
                                        name={key}
                                        label={key
                                          .replace(/_/g, " ")
                                          .toLowerCase()
                                          .split(" ")
                                          .map(
                                            (word) =>
                                              word.charAt(0).toUpperCase() +
                                              word.slice(1)
                                          )
                                          .join(" ")}
                                        value={value}
                                        onChange={(e) => {
                                          const newValue =
                                            e.target.value.replace(/,/g, ""); // Remove commas
                                          handlePersonalInformation({
                                            target: {
                                              name: key,
                                              value: newValue,
                                            },
                                          });
                                        }}
                                        error={Boolean(regexErrors[key])}
                                        helperText={regexErrors[key]}
                                      />
                                    </Grid>
                                  );
                                }
                              )}
                              {Object.entries(personalInformation).map(
                                ([key, value]) => {
                                  if (
                                    key != "shop_name" &&
                                    key != "shop_address" &&
                                    key != "mothers_name" &&
                                    key != "landmark"
                                  ) {
                                    return null; // Skip the iteration for "aadhar_info" key
                                  }
                                  return (
                                    <Grid
                                      key={key}
                                      item
                                      xs={12}
                                      sm={6}
                                      md={6}
                                      mt={3}
                                      style={{zIndex: 0}}
                                    >
                                      <TextField
                                        fullWidth
                                        type="text"
                                        variant="outlined"
                                        name={key}
                                        label={key
                                          .replace(/_/g, " ")
                                          .toLowerCase()
                                          .split(" ")
                                          .map(
                                            (word) =>
                                              word.charAt(0).toUpperCase() +
                                              word.slice(1)
                                          )
                                          .join(" ")}
                                        value={value}
                                        onChange={(e) => {
                                          const newValue =
                                            e.target.value.replace(/,/g, ""); // Remove commas
                                          handlePersonalInformation({
                                            target: {
                                              name: key,
                                              value: newValue,
                                            },
                                          });
                                        }}
                                        error={Boolean(regexErrors[key])}
                                        helperText={regexErrors[key]}
                                      />
                                    </Grid>
                                  );
                                }
                              )}
                              {Object.entries(personalInformation).map(
                                ([key, value]) => {
                                  if (key != "Loan_Tenure") {
                                    return null; // Skip the iteration for "aadhar_info" key
                                  }
                                  return (
                                    <Grid
                                      key={key}
                                      item
                                      xs={12}
                                      sm={6}
                                      md={6}
                                      mt={3}
                                      style={{zIndex: 0}}
                                    >
                                      <TextField
                                        fullWidth
                                        type="text"
                                        variant="outlined"
                                        name={key}
                                        label={key
                                          .replace(/_/g, " ")
                                          .toLowerCase()
                                          .split(" ")
                                          .map(
                                            (word) =>
                                              word.charAt(0).toUpperCase() +
                                              word.slice(1)
                                          )
                                          .join(" ")}
                                        value={value}
                                        onChange={(e) => {
                                          const newValue =
                                            e.target.value.replace(/,/g, ""); // Remove commas
                                          handlePersonalInformation({
                                            target: {
                                              name: key,
                                              value: newValue,
                                            },
                                          });
                                        }}
                                        error={Boolean(regexErrors[key])}
                                        helperText={regexErrors[key]}
                                      />
                                    </Grid>
                                  );
                                }
                              )}
                              {Object.entries(personalInformation).map(
                                ([key, value]) => {
                                  if (key != "own_house") {
                                    return null; // Skip the iteration for "aadhar_info" key
                                  }
                                  return (
                                    <Grid
                                      key={key}
                                      item
                                      xs={12}
                                      sm={6}
                                      md={6}
                                      mt={3}
                                      style={{zIndex: 0}}
                                    >
                                      <TextField
                                        fullWidth
                                        type="text"
                                        variant="outlined"
                                        name={key}
                                        label={key
                                          .replace(/_/g, " ")
                                          .toLowerCase()
                                          .split(" ")
                                          .map(
                                            (word) =>
                                              word.charAt(0).toUpperCase() +
                                              word.slice(1)
                                          )
                                          .join(" ")}
                                        value={value}
                                        onChange={(e) => {
                                          const newValue =
                                            e.target.value.replace(/,/g, ""); // Remove commas
                                          handlePersonalInformation({
                                            target: {
                                              name: key,
                                              value: newValue,
                                            },
                                          });
                                        }}
                                        error={Boolean(regexErrors[key])}
                                        helperText={regexErrors[key]}
                                      />
                                    </Grid>
                                  );
                                }
                              )}
                              {Object.entries(personalInformation).map(
                                ([key, value]) => {
                                  if (key != "Email_Id") {
                                    return null; // Skip the iteration for "aadhar_info" key
                                  }
                                  return (
                                    <Grid
                                      key={key}
                                      item
                                      xs={12}
                                      sm={6}
                                      md={6}
                                      mt={3}
                                      style={{zIndex: 0}}
                                    >
                                      <TextField
                                        fullWidth
                                        type="text"
                                        variant="outlined"
                                        name={key}
                                        label={key
                                          .replace(/_/g, " ")
                                          .toLowerCase()
                                          .split(" ")
                                          .map(
                                            (word) =>
                                              word.charAt(0).toUpperCase() +
                                              word.slice(1)
                                          )
                                          .join(" ")}
                                        value={value}
                                        onChange={(e) => {
                                          const newValue =
                                            e.target.value.replace(/,/g, ""); // Remove commas
                                          handlePersonalInformation({
                                            target: {
                                              name: key,
                                              value: newValue,
                                            },
                                          });
                                        }}
                                        error={Boolean(regexErrors[key])}
                                        helperText={regexErrors[key]}
                                      />
                                    </Grid>
                                  );
                                }
                              )}
                              {Object.entries(personalInformation).map(
                                ([key, value]) => {
                                  if (
                                    key != "Aadhar" &&
                                    key != "face_match_remarks"
                                  ) {
                                    return null; // Skip the iteration for "aadhar_info" key
                                  }
                                  return (
                                    <Grid
                                      key={key}
                                      item
                                      xs={12}
                                      sm={6}
                                      md={6}
                                      mt={3}
                                      style={{zIndex: 0}}
                                    >
                                      <TextField
                                        fullWidth
                                        type="text"
                                        variant="outlined"
                                        name={key}
                                        label={key
                                          .replace(/_/g, " ")
                                          .toLowerCase()
                                          .split(" ")
                                          .map(
                                            (word) =>
                                              word.charAt(0).toUpperCase() +
                                              word.slice(1)
                                          )
                                          .join(" ")}
                                        value={value}
                                        onChange={(e) => {
                                          const newValue =
                                            e.target.value.replace(/,/g, ""); // Remove commas
                                          handlePersonalInformation({
                                            target: {
                                              name: key,
                                              value: newValue,
                                            },
                                          });
                                        }}
                                        error={Boolean(regexErrors[key])}
                                        helperText={regexErrors[key]}
                                      />
                                    </Grid>
                                  );
                                }
                              )}
                              <Grid container width={"100%"}>
                                <Grid
                                  item
                                  md={12}
                                  lg={12}
                                  style={{
                                    textAlign: "center",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  <Typography
                                    sx={{ mr: 4, flex: 1, pt: 2, pb: 2 }}
                                    component="div"
                                    style={{
                                      fontWeight: "800",
                                      color: "#3f48cc",
                                    }}
                                  >
                                    Live Photo ({faceMatchRemarks})
                                  </Typography>
                                  {Object.entries(personalInformation).map(
                                    ([key, value]) => {
                                      if (key != "live_photo") {
                                        return null; // Skip the iteration for "aadhar_info" key
                                      }
                                      return (
                                        <Grid
                                          key={key}
                                          item
                                          xs={12}
                                          sm={12}
                                          md={12}
                                          mt={3}
                                        >
                                          <img
                                            src={`data:image/jpeg;base64, ${value}`}
                                            alt="Live Photo"
                                          />
                                        </Grid>
                                      );
                                    }
                                  )}
                                </Grid>
                              </Grid>
                              {personalInformation.aadhar_info && (
                                <>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    mt={3}
                                    style={{
                                      textAlign: "center",
                                      justifyContent: "center",
                                      width: "100%",
                                    }}
                                  >
                                    <Typography
                                      sx={{ mr: 4, flex: 1, pt: 2, pb: 2 }}
                                      component="div"
                                      style={{
                                        fontWeight: "800",
                                        color: "#3f48cc",
                                      }}
                                    >
                                      Aadhar Information
                                    </Typography>
                                  </Grid>
                                  {Object.entries(
                                    personalInformation.aadhar_info
                                  ).map(([aadharKey, aadharValue]) => (
                                    <>
                                      {aadharKey === "photo" && (
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={12}
                                          mt={3}
                                          style={{ textAlign: "center" }}
                                        >
                                          <img
                                            src={`data:image/jpeg;base64, ${aadharValue}`}
                                            alt="Aadhar Photo"
                                          />
                                        </Grid>
                                      )}
                                    </>
                                  ))}
                                  {Object.entries(
                                    personalInformation.aadhar_info
                                  ).map(([aadharKey, aadharValue]) => (
                                    <>
                                      {aadharKey !== "photo" && (
                                        <Grid item xs={12} sm={6} md={6} mt={3}>
                                          <TextField
                                            key={aadharKey}
                                            fullWidth
                                            disabled={true}
                                            type="text"
                                            variant="outlined"
                                            name={aadharKey}
                                            label={aadharKey
                                              .replace(/_/g, " ")
                                              .toLowerCase()
                                              .split(" ")
                                              .map(
                                                (word) =>
                                                  word.charAt(0).toUpperCase() +
                                                  word.slice(1)
                                              )
                                              .join(" ")}
                                            value={aadharValue}
                                          />
                                        </Grid>
                                      )}
                                    </>
                                  ))}
                                </>
                              )}
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                            style={{
                              width: "100%",
                            }}
                          >
                            <Button
                              sx={{ textTransform: "none" }}
                              disabled={currentUser.role_name != "credit_head" || disableUpdate}
                              variant="contained"
                              className="download-button"
                              onClick={updatePersonalDetails}
                            >
                              Update
                            </Button>
                          </Grid>
                          {/* <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                            style={{
                              width: "100%",
                            }}
                          >
                            <object
                              data={`data:application/pdf;base64,${base64file}`}
                              type="application/pdf"
                              width="100%"
                              height="500px" // Adjust the height as needed
                            >
                              <p>
                                Your browser does not support displaying PDFs.
                              </p>
                            </object>
                          </Grid> */}
                        </div>
                      ) : null}
                    </>
                  ) : null}
                </TabPanel>
                <TabPanel value={value} index={1}>
                  {guarantorInformation != undefined &&
                  guarantorInformation != "" ? (
                    <div className="details-box">
                      <Grid item xs={12} sx={{ pt: 4 }}>
                        {guarantorInformation.map((subList, index) => (
                          <Grid
                            container
                            spacing={4}
                            sx={{ pl: 1, pr: 1 }}
                            key={index}
                          >
                            <Grid item xs={12} sm={12} md={12} mt={3}>
                              <Typography
                                sx={{ mr: 4, flex: 1, pt: 2, pb: 2 }}
                                component="div"
                                style={{ fontWeight: "800", color: "#3f48cc" }}
                              >
                                Loan Guarantor {index + 1}
                              </Typography>
                            </Grid>
                            {Object.entries(subList).map(([key, value]) => {
                              if (key === "aadhar_info") {
                                return null; // Skip the iteration for "aadhar_info" key
                              }
                              return (
                                <>
                                  {key != "Filepath" ? (
                                    <Grid
                                      key={key}
                                      item
                                      xs={12}
                                      sm={6}
                                      md={6}
                                      mt={3}
                                    >
                                      <TextField
                                        fullWidth
                                        type="text"
                                        variant="outlined"
                                        name={key}
                                        label={key
                                          .replace(/_/g, " ")
                                          .toLowerCase()
                                          .split(" ")
                                          .map(
                                            (word) =>
                                              word.charAt(0).toUpperCase() +
                                              word.slice(1)
                                          )
                                          .join(" ")}
                                        value={value}
                                        onChange={(e) =>
                                          handleGuarantorInformation(e, index)
                                        }
                                      />
                                    </Grid>
                                  ) : (
                                    <Grid
                                      item
                                      xs={12}
                                      md={6}
                                      sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                      }}
                                      style={{
                                        width: "100%",
                                      }}
                                    >
                                      <Button
                                        sx={{ textTransform: "none" }}
                                        variant="contained"
                                        className="download-button"
                                        onClick={(e) => downloadFile(value)}
                                      >
                                        Download Documents
                                      </Button>
                                    </Grid>
                                  )}
                                </>
                              );
                            })}

                            {subList.aadhar_info && (
                              <>
                                <Grid item xs={12} sm={12} md={12} mt={3}>
                                  <Typography
                                    sx={{ mr: 4, flex: 1, pt: 2, pb: 2 }}
                                    component="div"
                                    style={{
                                      fontWeight: "800",
                                      color: "#3f48cc",
                                      textAlign: "center",
                                    }}
                                  >
                                    Aadhar Info
                                  </Typography>
                                </Grid>
                                {Object.entries(subList.aadhar_info).map(
                                  ([aadharKey, aadharValue]) => (
                                    <>
                                      {aadharKey === "photo" && (
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={12}
                                          mt={3}
                                          style={{ textAlign: "center" }}
                                        >
                                          <img
                                            src={`data:image/jpeg;base64, ${aadharValue}`}
                                            alt="Aadhar Photo"
                                          />
                                        </Grid>
                                      )}
                                    </>
                                  )
                                )}
                                {Object.entries(subList.aadhar_info).map(
                                  ([aadharKey, aadharValue]) => (
                                    <>
                                      {aadharKey !== "photo" && (
                                        <Grid item xs={12} sm={6} md={6} mt={3}>
                                          <TextField
                                            key={aadharKey}
                                            fullWidth
                                            disabled={true}
                                            type="text"
                                            variant="outlined"
                                            name={aadharKey}
                                            label={aadharKey
                                              .replace(/_/g, " ")
                                              .toLowerCase()
                                              .split(" ")
                                              .map(
                                                (word) =>
                                                  word.charAt(0).toUpperCase() +
                                                  word.slice(1)
                                              )
                                              .join(" ")}
                                            value={aadharValue}
                                          />
                                        </Grid>
                                      )}
                                    </>
                                  )
                                )}
                              </>
                            )}
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                              style={{
                                width: "100%",
                              }}
                            >
                              <Button
                                sx={{ textTransform: "none" }}
                                disabled={
                                  currentUser.role_name != "credit_head"
                                }
                                variant="contained"
                                className="download-button"
                                onClick={updateGuarantorDetails}
                              >
                                Update
                              </Button>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    </div>
                  ) : null}
                </TabPanel>
                <TabPanel value={value} index={2}>
                  {loanType == "Individual" ? (
                    <>
                      {coapplicantInformation && Array.isArray(coapplicantInformation) && coapplicantInformation.length > 0 ? (
                        <div className="details-box">
                          <Grid item xs={12} sx={{ pt: 4 }}>
                            <Grid container spacing={4} sx={{ pl: 1, pr: 1 }}>
                              <Grid item xs={11} sm={11} md={11}>
                                <Typography
                                  sx={{ mr: 4, flex: 1, pt: 2, pb: 2 }}
                                  component="div"
                                  style={{
                                    fontWeight: "800",
                                    color: "#3f48cc",
                                  }}
                                >
                                  Co-Applicant Information
                                </Typography>
                              </Grid>
                              {coapplicantInformation?.map((applicant, index) => (
                                <Box
                                  key={index}
                                  sx={{
                                    marginBottom: 2,
                                    padding: 4,
                                    border: '1px solid #ddd',
                                    borderRadius: '4px',
                                    marginLeft: "20px",
                                    width: '100%', 
                                  }}
                                >
                                  <Typography variant="h6">Co-Applicant {index + 1}</Typography>
                                  <Grid container spacing={2}>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={6}
                                    mt={3}
                                    style={{zIndex: 0}}
                                  >
                                    <TextField
                                      fullWidth
                                      type="text"
                                      variant="outlined"
                                      name="full_name"
                                      label="Full Name"
                                      value={applicant?.full_name || "-"}
                                      disabled={true}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={6}
                                    mt={3}
                                  >
                                    <TextField
                                      fullWidth
                                      type="text"
                                      variant="outlined"
                                      name="email_id"
                                      label="Email ID"
                                      value={applicant?.email_id || "-"}
                                      disabled={true}
                                    />
                                  </Grid>
                                  </Grid>
                                  <Grid container spacing={2}>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={6}
                                    mt={3}
                                  >
                                    <TextField
                                      fullWidth
                                      type="text"
                                      variant="outlined"
                                      name="pan"
                                      label="PAN"
                                      value={applicant?.pan || "-"}
                                      disabled={true}
                                      style={{zIndex: 0}}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={6}
                                    mt={3}
                                  >
                                    <TextField
                                      fullWidth
                                      type="text"
                                      variant="outlined"
                                      name="phone_no"
                                      label="Phone No"
                                      value={applicant?.phone_no || "-"}
                                      disabled={true}
                                    />
                                  </Grid>
                                  </Grid>
                                  <Typography style={{ fontSize: "16px", marginTop: "20px" }}>Aadhar Details</Typography>
                                  <Grid container spacing={2}>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={6}
                                    mt={3}
                                  >
                                    <TextField
                                      fullWidth
                                      type="text"
                                      variant="outlined"
                                      name="gender"
                                      label="Gender"
                                      value={applicant?.aadhaar_data?.data?.gender}
                                      disabled={true}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={6}
                                    mt={3}
                                  >
                                    <TextField
                                      fullWidth
                                      type="text"
                                      variant="outlined"
                                      name="dob"
                                      label="Date Of Birth"
                                      value={applicant?.aadhaar_data?.data?.dateOfBirth}
                                      disabled={true}
                                    />
                                  </Grid>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={6}
                                    mt={3}
                                  >
                                    <TextField
                                      fullWidth
                                      type="text"
                                      variant="outlined"
                                      name="address"
                                      label="Address"
                                      value={`${applicant?.aadhaar_data?.data?.address?.street ? `${applicant?.aadhaar_data?.data?.address?.street}, ` : ''}${applicant?.aadhaar_data?.data?.address?.district ? `${applicant?.aadhaar_data?.data?.address?.district}, ` : ''}${applicant?.aadhaar_data?.data?.address?.state ? `${applicant?.aadhaar_data?.data?.address?.state}, ` : ''}`}
                                      disabled={true}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    mt={12}
                                    sx={{ display: 'flex', justifyContent: 'center' }}
                                  >
                                    <img src={`data:image/jpeg;base64, ${applicant?.aadhaar_data?.data?.photo}`} alt="Co-Applicant Image" />
                                  </Grid>
                                </Box>
                              ))}
                            </Grid>
                          </Grid>
                        </div>
                      ) : null}
                    </>
                  ) : null}
                </TabPanel>
              </>
            ) : (
              <>
                <TabPanel value={value} index={0}>
                  {loanType == "Company" ? (
                    <>
                      {company_address != undefined ? (
                        <div className="details-box">
                          <Grid item xs={12} sx={{ pt: 4 }}>
                            <Grid container spacing={4} sx={{ pl: 1, pr: 1 }}>
                              <Grid item xs={11} sm={11} md={11}>
                                <Typography
                                  sx={{ mr: 4, flex: 1, pt: 2, pb: 2 }}
                                  component="div"
                                  style={{
                                    fontWeight: "800",
                                    color: "#3f48cc",
                                  }}
                                >
                                  Company Information
                                </Typography>
                              </Grid>
                              {currentUser.role_name != "branch_manager" ? (
                                <Grid
                                  item
                                  xs={12}
                                  md={1}
                                  sx={{ display: "flex", mt: 3 }}
                                >
                                  {/* Upload Icon with Tooltip */}
                                  <Tooltip
                                    title="Upload MSME Certificate"
                                    arrow
                                  >
                                    <div
                                      onClick={handleUploadClick}
                                      style={{
                                        cursor: "pointer",
                                        color: "#3f48cc",
                                      }}
                                    >
                                      <UploadIcon sx={{ m: 1 }} />
                                    </div>
                                  </Tooltip>
                                  {/* {base64file != null &&
                                  base64file != undefined ? (
                                    <>
                                      {" "}
                                      <Tooltip
                                        title="Download MSME Certificate"
                                        arrow
                                      >
                                        <div
                                          onClick={downloadIdentificationCard}
                                          style={{
                                            cursor: "pointer",
                                            color: "#3f48cc",
                                          }}
                                        >
                                          <DownloadIcon sx={{ m: 1 }} />
                                        </div>
                                      </Tooltip>
                                    </>
                                  ) : null} */}

                                  {/* Hidden file input */}
                                  <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={{ display: "none" }}
                                    onChange={handleFileChange}
                                  />
                                </Grid>
                              ) : null}
                              {Object.entries(company_address).map(
                                ([key, value]) => (
                                  <>
                                    {key == "industry_type" ? (
                                      <Grid
                                        key={key}
                                        item
                                        xs={12}
                                        sm={6}
                                        md={6}
                                        mt={3}
                                      >
                                        <TextField
                                          fullWidth
                                          type="text"
                                          variant="outlined"
                                          name={key}
                                          label="Nature of Business"
                                          value={value}
                                          onChange={(e) =>
                                            handleCompanyInformation(e)
                                          }
                                        />
                                      </Grid>
                                    ) : (
                                      <Grid
                                        key={key}
                                        item
                                        xs={12}
                                        sm={6}
                                        md={6}
                                        mt={3}
                                      >
                                        <TextField
                                          fullWidth
                                          type="text"
                                          variant="outlined"
                                          name={key}
                                          label={key
                                            .replace(/_/g, " ")
                                            .toLowerCase()
                                            .split(" ")
                                            .map(
                                              (word) =>
                                                word.charAt(0).toUpperCase() +
                                                word.slice(1)
                                            )
                                            .join(" ")}
                                          value={value}
                                          onChange={(e) =>
                                            handleCompanyInformation(e)
                                          }
                                        />
                                      </Grid>
                                    )}
                                  </>
                                )
                              )}
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                              style={{
                                width: "100%",
                              }}
                            >
                              <Button
                                sx={{ textTransform: "none" }}
                                disabled={
                                  currentUser.role_name != "credit_head"
                                }
                                variant="contained"
                                className="download-button"
                                onClick={updateCompanyDetails}
                              >
                                Update
                              </Button>
                            </Grid>
                          </Grid>
                        </div>
                      ) : null}
                    </>
                  ) : null}
                </TabPanel>
                <TabPanel value={value} index={1}>
                  {loanType == "Company" ? (
                    <>
                      {director_details != undefined ? (
                        <>
                          <Grid item xs={12} sx={{ pt: 4 }}>
                            {director_details.map((subList, index) => (
                              <div className="details-box">
                                <Grid
                                  container
                                  spacing={4}
                                  sx={{ pl: 1, pr: 1 }}
                                  key={index}
                                >
                                  <Grid item xs={12} sm={12} md={12} mt={3}>
                                    <Typography
                                      sx={{ mr: 4, flex: 1, pt: 2, pb: 2 }}
                                      component="div"
                                      style={{
                                        fontWeight: "800",
                                        color: "#3f48cc",
                                        textAlign: "center",
                                      }}
                                    >
                                      Director {index + 1}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    mt={3}
                                    style={{
                                      textAlign: "center",
                                    }}
                                  ></Grid>
                                  {Object.entries(subList).map(
                                    ([key, value]) => {
                                      if (
                                        key === "aadhar_info" ||
                                        key == "company_director_details_id" ||
                                        key == "company_id"
                                      ) {
                                        return null; // Skip the iteration for "aadhar_info" key
                                      }
                                      return (
                                        <Grid
                                          key={key}
                                          item
                                          xs={12}
                                          sm={6}
                                          md={6}
                                          mt={3}
                                        >
                                          <TextField
                                            fullWidth
                                            type="text"
                                            variant="outlined"
                                            name={key}
                                            label={key
                                              .replace(/_/g, " ")
                                              .toLowerCase()
                                              .split(" ")
                                              .map(
                                                (word) =>
                                                  word.charAt(0).toUpperCase() +
                                                  word.slice(1)
                                              )
                                              .join(" ")}
                                            value={value}
                                            onChange={(e) =>
                                              handleDirectorInformation(
                                                e,
                                                index
                                              )
                                            }
                                          />
                                        </Grid>
                                      );
                                    }
                                  )}

                                  {subList.aadhar_info && (
                                    <>
                                      <Grid item xs={12} sm={12} md={12} mt={3}>
                                        <Typography
                                          sx={{ mr: 4, flex: 1, pt: 2, pb: 2 }}
                                          component="div"
                                          style={{
                                            fontWeight: "800",
                                            color: "#3f48cc",
                                            textAlign: "center",
                                          }}
                                        >
                                          Aadhar Info
                                        </Typography>
                                      </Grid>
                                      {Object.entries(subList.aadhar_info).map(
                                        ([aadharKey, aadharValue]) => (
                                          <>
                                            {aadharKey === "photo" && (
                                              <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                mt={3}
                                                style={{ textAlign: "center" }}
                                              >
                                                <img
                                                  src={`data:image/jpeg;base64, ${aadharValue}`}
                                                  alt="Aadhar Photo"
                                                />
                                              </Grid>
                                            )}
                                          </>
                                        )
                                      )}
                                      {Object.entries(subList.aadhar_info).map(
                                        ([aadharKey, aadharValue]) => (
                                          <>
                                            {aadharKey !== "photo" && (
                                              <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={6}
                                                mt={3}
                                              >
                                                <TextField
                                                  key={aadharKey}
                                                  fullWidth
                                                  disabled={true}
                                                  type="text"
                                                  variant="outlined"
                                                  name={aadharKey}
                                                  label={aadharKey
                                                    .replace(/_/g, " ")
                                                    .toLowerCase()
                                                    .split(" ")
                                                    .map(
                                                      (word) =>
                                                        word
                                                          .charAt(0)
                                                          .toUpperCase() +
                                                        word.slice(1)
                                                    )
                                                    .join(" ")}
                                                  value={aadharValue}
                                                />
                                              </Grid>
                                            )}
                                          </>
                                        )
                                      )}
                                    </>
                                  )}
                                </Grid>
                              </div>
                            ))}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                            style={{
                              width: "100%",
                            }}
                          >
                            <Button
                              sx={{ textTransform: "none" }}
                              disabled={currentUser.role_name != "credit_head"}
                              variant="contained"
                              className="download-button"
                              onClick={updateDirectorDetails}
                            >
                              Update
                            </Button>
                          </Grid>
                        </>
                      ) : null}
                    </>
                  ) : null}
                </TabPanel>
                <TabPanel value={value} index={2}>
                  {guarantorInformation != undefined &&
                  guarantorInformation != "" ? (
                    <div className="details-box">
                      <Grid item xs={12} sx={{ pt: 4 }}>
                        {guarantorInformation.map((subList, index) => (
                          <Grid
                            container
                            spacing={4}
                            sx={{ pl: 1, pr: 1 }}
                            key={index}
                          >
                            <Grid item xs={12} sm={12} md={12} mt={3}>
                              <Typography
                                sx={{ mr: 4, flex: 1, pt: 2, pb: 2 }}
                                component="div"
                                style={{ fontWeight: "800", color: "#3f48cc" }}
                              >
                                Loan Guarantor {index + 1}
                              </Typography>
                            </Grid>
                            {Object.entries(subList).map(([key, value]) => {
                              if (
                                key === "aadhar_info" ||
                                key == "id" ||
                                key == "loan_id" ||
                                key == "File_path"
                              ) {
                                return null; // Skip the iteration for "aadhar_info" key
                              }
                              return (
                                <>
                                  {key != "Filepath" && key != "aadhar_info" ? (
                                    <Grid
                                      key={key}
                                      item
                                      xs={12}
                                      sm={6}
                                      md={6}
                                      mt={3}
                                    >
                                      <TextField
                                        fullWidth
                                        type="text"
                                        variant="outlined"
                                        name={key}
                                        label={key
                                          .replace(/_/g, " ")
                                          .toLowerCase()
                                          .split(" ")
                                          .map(
                                            (word) =>
                                              word.charAt(0).toUpperCase() +
                                              word.slice(1)
                                          )
                                          .join(" ")}
                                        value={value}
                                        onChange={(e) =>
                                          handleGuarantorInformation(e, index)
                                        }
                                      />
                                    </Grid>
                                  ) : null}
                                  {key == "Filepath" && key != "aadhar_info" ? (
                                    <Grid
                                      item
                                      xs={12}
                                      md={6}
                                      sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                      }}
                                      style={{
                                        width: "100%",
                                      }}
                                    >
                                      <Button
                                        sx={{ textTransform: "none" }}
                                        variant="contained"
                                        className="download-button"
                                        onClick={(e) => downloadFile(value)}
                                      >
                                        Download Documents
                                      </Button>
                                    </Grid>
                                  ) : null}
                                </>
                              );
                            })}

                            {subList.aadhar_info && (
                              <>
                                <Grid item xs={12} sm={12} md={12} mt={3}>
                                  <Typography
                                    sx={{ mr: 4, flex: 1, pt: 2, pb: 2 }}
                                    component="div"
                                    style={{
                                      fontWeight: "800",
                                      color: "#3f48cc",
                                      textAlign: "center",
                                    }}
                                  >
                                    Aadhar Info
                                  </Typography>
                                </Grid>
                                {Object.entries(subList.aadhar_info).map(
                                  ([aadharKey, aadharValue]) => (
                                    <>
                                      {aadharKey === "photo" && (
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={12}
                                          mt={3}
                                          style={{ textAlign: "center" }}
                                        >
                                          <img
                                            src={`data:image/jpeg;base64, ${aadharValue}`}
                                            alt="Aadhar Photo"
                                          />
                                        </Grid>
                                      )}
                                    </>
                                  )
                                )}
                                {Object.entries(subList.aadhar_info).map(
                                  ([aadharKey, aadharValue]) => (
                                    <>
                                      {aadharKey !== "photo" && (
                                        <Grid item xs={12} sm={6} md={6} mt={3}>
                                          <TextField
                                            key={aadharKey}
                                            fullWidth
                                            disabled={true}
                                            type="text"
                                            variant="outlined"
                                            name={aadharKey}
                                            label={aadharKey
                                              .replace(/_/g, " ")
                                              .toLowerCase()
                                              .split(" ")
                                              .map(
                                                (word) =>
                                                  word.charAt(0).toUpperCase() +
                                                  word.slice(1)
                                              )
                                              .join(" ")}
                                            value={aadharValue}
                                          />
                                        </Grid>
                                      )}
                                    </>
                                  )
                                )}
                              </>
                            )}
                          </Grid>
                        ))}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                        style={{
                          width: "100%",
                        }}
                      >
                        <Button
                          sx={{ textTransform: "none" }}
                          disabled={currentUser.role_name != "credit_head"}
                          variant="contained"
                          className="download-button"
                          onClick={updateGuarantorDetails}
                        >
                          Update
                        </Button>
                      </Grid>
                    </div>
                  ) : null}
                </TabPanel>
              </>
            )}
          </div>
        </Box>
      </Box>
      <Footer />
    </ThemeProvider>
  );
}

export default function Dashboard() {
  return <DashboardContent />;
}
