import React from "react";
import { Toolbar, IconButton, Typography, Tooltip } from "@mui/material";
import { Menu as MenuIcon, Logout as LogoutIcon } from "@mui/icons-material";
import { logout } from "./../apiCalls/api";
const AppHeader = ({ open, toggleDrawer, title }) => {
const handleLogout = async () => {
    try {
      const result = await logout();
      console.log("Logout successful", result);
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = "./login";
    } catch (error) {
      console.error("Error during logout:", error.message);
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = "./login";
    }
  };
  return (
    <>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="open drawer"
        onClick={toggleDrawer}
        sx={{
          marginRight: "36px",
          ...(open && { display: "none" }),
        }}
      >
        <MenuIcon />
      </IconButton>
      <Typography
        component="h1"
        variant="h6"
        color="inherit"
        noWrap
        sx={{ flexGrow: 1 }}
      >
        {title}
      </Typography>
      <Tooltip title="Logout " sx={{ mt: -1 }}>
        <IconButton color="inherit" onClick={handleLogout}>
          <LogoutIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default AppHeader;
